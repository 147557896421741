/* eslint-disable no-undef */
/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import firebase from "firebase/compat/app"

// Add the Firebase products that you want to use
import "firebase/compat/auth"
import "firebase/compat/firestore"
import "firebase/compat/storage"

import Auth from "./firestore/auth"
import BusinessPoint from "./firestore/business-point"
import BusinessPointSubs from "./firestore/business-point-subs"
import Product from "./firestore/product"
import Planogram from "./firestore/planogram"
import Transaction from "./firestore/transaction"
import Snapshot from "./firestore/snapshot"
import Csv from "./firestore/csv"
import ProductStorage from "./storage/product"
import InvoiceStorage from "./storage/invoice"
import BuktiTransferStorage from "./storage/buktitransfer"
import Payout from "./firestore/payout"
import Balance from "./firestore/balance"
import Chat from "./firestore/chat"
import Config from "./firestore/config"
import Refund from "./firestore/refund"
import Refiller from "./firestore/refiller"
import Role from "./firestore/role"
import Mapping from "./firestore/maping"
import BpFee from "./firestore/bpfee"
import SnapshotDaily from "./firestore/snapshot-daily"
import SnapshotMonthly from "./firestore/snapshot-monthly"
import Navigation from "./firestore/navigation"
import Account from "./firestore/account"
import ErrorEx from "./firestore/errorExplanation"
import Subscriptions from "./firestore/subscriptions"
import Discount from "./firestore/discount"
import Invoice from "./firestore/invoice"
import Category from "./firestore/category"
import Ingredient from "./firestore/ingredient"
import { APPLICATION_ID } from "Apps"

class FirebaseHelper {
  constructor(firebaseConfig) {
    if (firebaseConfig) {
      // Initialize Firebase
      firebase.initializeApp(firebaseConfig)
      firebase.auth().onAuthStateChanged(user => {
        // if (user) {
        //   localStorage.setItem("authUser", JSON.stringify(user))
        // } else {
        //   localStorage.removeItem("authUser")
        // }
      })
    }

    // Firestore query
    this.auth = new Auth(firebase, APPLICATION_ID);
    this.businessPoint = new BusinessPoint(firebase, APPLICATION_ID);
    this.bpSubs = new BusinessPointSubs(firebase, APPLICATION_ID);
    this.product = new Product(firebase, APPLICATION_ID);
    this.planogram = new Planogram(firebase, APPLICATION_ID);
    this.transaction = new Transaction(firebase, APPLICATION_ID);
    this.discount = new Discount(firebase, APPLICATION_ID);
    this.snapshot = new Snapshot(firebase, APPLICATION_ID);
    this.csv = new Csv(firebase, APPLICATION_ID);
    this.balance = new Balance(firebase, APPLICATION_ID);
    this.payout = new Payout(firebase, APPLICATION_ID);
    this.chat = new Chat(firebase, APPLICATION_ID);
    this.config = new Config(firebase, APPLICATION_ID);
    this.refund = new Refund(firebase, APPLICATION_ID);
    this.refiller = new Refiller(firebase, APPLICATION_ID);
    this.mapping = new Mapping(firebase, APPLICATION_ID)
    this.bpfee = new BpFee(firebase, APPLICATION_ID)
    this.snapshotDaily = new SnapshotDaily(firebase, APPLICATION_ID)
    this.ingredient = new Ingredient(firebase, APPLICATION_ID)
    this.snapshotMonthly = new SnapshotMonthly(firebase, APPLICATION_ID)
    this.role = new Role(firebase, APPLICATION_ID);
    this.navigation = new Navigation(firebase, APPLICATION_ID)
    this.account = new Account(firebase, APPLICATION_ID)
    this.errorEx = new ErrorEx(firebase, APPLICATION_ID)
    this.subs = new Subscriptions(firebase, APPLICATION_ID)
    this.invoice = new Invoice(firebase, APPLICATION_ID)
    this.category = new Category(firebase, APPLICATION_ID)
    // Firebase Storage
    this.productStorage = new ProductStorage(firebase, APPLICATION_ID);
    this.invoiceStorage = new InvoiceStorage(firebase, APPLICATION_ID);
    this.buktitransferStorage = new BuktiTransferStorage(firebase, APPLICATION_ID);
  }

  /**
   * Registers the user with given details
   */
  registerUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Registers the user with given details
   */
  editProfileAPI = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          user => {
            resolve(firebase.auth().currentUser)
          },
          error => {
            reject(this._handleError(error))
          }
        )
    })
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = email => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email, {
          url:
            window.location.protocol + "//" + window.location.host + "/login",
        })
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Logout the user
   */
  logout = () => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signOut()
        .then(() => {
          resolve(true)
        })
        .catch(error => {
          reject(this._handleError(error))
        })
    })
  }

  /**
   * Social Login user with given details
   */
  socialLoginUser = (data, type) => {
    let credential = {}
    if (type === "google") {
      credential = firebase.auth.GoogleAuthProvider.credential(data.idToken, data.token)
    } else if (type === "facebook") {
      credential = firebase.auth.FacebookAuthProvider.credential(data.token)
    }
    return new Promise((resolve, reject) => {
      if (!!credential) {
        firebase.auth().signInWithCredential(credential)
          .then(user => {
            resolve(this.addNewUserToFirestore(user))
          })
          .catch(error => {
            reject(this._handleError(error))
          })
      } else {
        reject(this._handleError(error))
      }
    })
  }

  addNewUserToFirestore = (user) => {
    const collection = firebase.firestore().collection("users")
    const { profile } = user.additionalUserInfo
    const details = {
      firstName: profile.given_name ? profile.given_name : profile.first_name,
      lastName: profile.family_name ? profile.family_name : profile.last_name,
      fullName: profile.name,
      email: profile.email,
      picture: profile.picture,
      createdDtm: firebase.firestore.FieldValue.serverTimestamp(),
      lastLoginTime: firebase.firestore.FieldValue.serverTimestamp()
    }
    collection.doc(firebase.auth().currentUser.uid).set(details)
    return { user, details }
  }

  // setLoggeedInUser = user => {
  //   localStorage.setItem("authUser", JSON.stringify(user))
  // }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem("authUser")) return null
    return JSON.parse(localStorage.getItem("authUser"))
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _firebaseInstance = null

/**
 * Initilize the instance
 * @param {*} config
 */
const init = config => {
  if (!_firebaseInstance) {
    _firebaseInstance = new FirebaseHelper(config)
  }
  return _firebaseInstance
}

/**
 * Returns the firebase instance
 */
const getInstance = () => {
  return _firebaseInstance
}

export { init, getInstance }

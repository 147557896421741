/* eslint-disable react/prop-types */
/* eslint-disable semi */
/* eslint-disable no-undef */
import React, { Component } from 'react';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from 'reactstrap';
import Result from 'iotera-base/result';

import { safeDeepGet, isEmpty, safeDeepSet } from 'iotera-base/utility/json';

// availity-reactstrap-validation
import { AvField, AvForm } from 'availity-reactstrap-validation';

// Redux
import { connect } from 'react-redux';

//Import Breadcrumb
import Breadcrumb from 'Base/components/Common/Breadcrumb';

import avatar from '../../../Base/assets/images/users/user1.png';
import { initCreate, create, read, update } from '../../store/account/actions';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
// actions

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = { email: '', name: '', idx: 1, loading: false };

    // handleValidSubmit
    this.handleValidSubmit = this.handleValidSubmit.bind(this);
    this.showToast = this.showToast.bind(this);
    this.changePhoneNumber = this.changePhoneNumber.bind(this);
  }

  // handleValidSubmit
  handleValidSubmit(event, values) {
    this.setState({ loading: true });

    setTimeout(
      function () {
        //Start the timer
        const phoneNumber = safeDeepGet(values, 'phone', '');
        safeDeepSet(values, 'phone', this.changePhoneNumber(phoneNumber));
        this.props.UpdateAccount(values);
      }.bind(this),
      2000
    );
  }

  changePhoneNumber = phone => {
    if (phone.startsWith('0')) {
      return '62' + phone.slice(1);
    }
    if (phone.startsWith('+')) {
      return phone.slice(1);
    }
    return phone;
  };

  componentDidMount() {
    const name = localStorage.getItem('name');
    this.props.ReadAccount(name);
  }

  showToast(toastType, message) {
    toastr.options = {
      closeButton: false,
      debug: false,
      newestOnTop: false,
      progressBar: true,
      positionClass: 'toast-top-right',
      preventDuplicates: false,
      onclick: null,
      showDuration: '300',
      hideDuration: '1000',
      timeOut: '5000',
      extendedTimeOut: '1000',
      showEasing: 'swing',
      hideEasing: 'linear',
      showMethod: 'fadeIn',
      hideMethod: 'fadeOut',
    };

    if (toastType === 'info') toastr.info(message);
    else if (toastType === 'warning') toastr.warning(message);
    else if (toastType === 'error') toastr.error(message);
    else toastr.success(message);
  }

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, ss) {
    if (this.props.updateResult !== prevProps.updateResult) {
      // Success update info
      const code = safeDeepGet(
        this.props,
        ['updateResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      this.setState({ loading: false });
      if (code === Result.SUCCESS) {
        this.showToast('success', 'Berhasil Update Account');
      } else if (code === Result.FAILED) {
        this.showToast('error', 'Gagal Update Account');
      }
    }
    if (this.props.readResult !== prevProps.readResult) {
      const code = safeDeepGet(
        this.props,
        ['readResult', 'code'],
        Result.UNKNOWN_ERROR
      );
      if (code == Result.SUCCESS) {
        const newState = {};
        const name = safeDeepGet(this.props, ['readResult', 'body', 'name']);
        newState['name'] = name;
        const email = safeDeepGet(this.props, ['readResult', 'body', 'email']);
        newState['email'] = email;
        const phone = safeDeepGet(this.props, ['readResult', 'body', 'phone']);
        newState['phone'] = phone;
        const role = safeDeepGet(this.props, ['readResult', 'body', 'role']);
        newState['role'] = role;
        if (!isEmpty(newState)) {
          this.setState(newState);
        }
      }
    }
  }

  render() {
    const { name, loading, email, phone } = this.state;
    return (
      <React.Fragment>
        <div className="page-content">
          <Container fluid>
            {/* Render Breadcrumb */}
            <Breadcrumb title="Account" breadcrumbItem="Profile" />

            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="d-flex">
                      <div className="me-3">
                        <img
                          src={avatar}
                          alt=""
                          className="avatar-md rounded-circle img-thumbnail"
                        />
                      </div>
                      <div className="align-self-center flex-1">
                        <div className="text-muted">
                          <h5>{name}</h5>
                          <p className="mb-1">{email}</p>
                          {/* <p className="mb-1">Role {role}</p> */}
                          <p className="mb-1">Phone +{phone}</p>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <h4 className="card-title mb-4">Change Data</h4>

            <Card>
              <CardBody>
                <AvForm
                  className="form-horizontal"
                  onValidSubmit={(e, v) => {
                    this.handleValidSubmit(e, v);
                  }}
                >
                  <div className="form-group">
                    <AvField
                      name="username"
                      label="User Name"
                      value={this.state.name}
                      className="form-control"
                      placeholder="Enter User Name"
                      type="text"
                      required
                    />
                    <AvField
                      name="phone"
                      label="Phone Number"
                      value={this.state.phone}
                      className="form-control"
                      placeholder="Enter Phone Number"
                      type="number"
                      required
                    />
                    <AvField
                      name="email"
                      value={this.state.email}
                      type="hidden"
                    />
                  </div>
                  <div className="text-center mt-4">
                    <Button type="submit" color="primary" disabled={loading}>
                      {!loading ? 'Update Data' : <Spinner />}
                    </Button>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  initCreateResult: safeDeepGet(state, ['accountRole', 'initCreate'], {}),
  createResult: safeDeepGet(state, ['accountRole', 'create'], {}),
  loading: safeDeepGet(state, ['accountRole', 'loading'], true),
  readResult: safeDeepGet(state, ['accountRole', 'read'], {}),
  updateResult: safeDeepGet(state, ['accountRole', 'update'], {}),
  test: state,
});

const mapDispatchToProps = dispatch => ({
  InitCreateAccount: () => dispatch(initCreate()),
  CreateAccount: payload => dispatch(create(payload)),
  ReadAccount: code => dispatch(read(code)),
  UpdateAccount: payload => dispatch(update(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);

/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import { safeDeepGet } from 'iotera-base/utility/json';
import Base from './base';

export default class Distributor extends Base {
  constructor(firebase, applicationId) {
    super(firebase, applicationId, 'error_explanation');
  }


  list = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };

  create = payload => {
    return new Promise((resolve, reject) => {
      const insertedPayload = {};
      for (const key in payload) {
        if (
          key === 'description' ||
          key === 'errorcode' ||
          key === 'type' ||
          key === 'name_type' ||
          key === 'handling'
        ) {
          insertedPayload[key] = payload[key];
        }
      }
      const id = payload.type + '_' + payload.errorcode;
      this.collection
        .doc(id)
        .set(insertedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  read = sku => {
    return new Promise((resolve, reject) => {
      let map = {};
      this.collection
        .doc(sku)
        .get()
        .then(
          querySnapshot => {
            let code = -1;
            if (querySnapshot.size > 0) {
              code = 0;
              map = { ...querySnapshot.docs[0].data() };
            }
            resolve({ code: code, body: map });
          },
          error => {
            resolve({ code: -1, body: map });
          }
        );
    });
  };

  update = (payload) => {
    return new Promise((resolve, reject) => {
      const updatedPayload = {};
      for (const key in payload) {
        if (
          key === 'description' ||
          key === 'errorcode' ||
          key === 'type' ||
          key === 'name_type' ||
          key === 'handling'
        ) {
          updatedPayload[key] = payload[key];
        }
      }
      const id = payload.type + '_' + payload.errorcode;
      this.collection
        .doc(id)
        .set(updatedPayload)
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };

  delete_ = id => {
    return new Promise((resolve, reject) => {
      this.collection
        .doc(id)
        .delete()
        .then(
          () => {
            resolve({ code: 0 });
          },
          error => {
            resolve({ code: -1, body: error });
          }
        );
    });
  };
}

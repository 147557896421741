/* eslint-disable no-unused-vars */
/* eslint-disable semi */
import no_image from '../../../Base/assets/images/no_image.png';
const multi = JSON.parse(localStorage.getItem('multitenancy') || 'false');
export default class Storage {
  constructor(firebase, applicationId) {
    this.storage = firebase.storage();
    this.resetApplicationId(applicationId);
  }

  resetApplicationId = applicationId => {
    this.basePath = this.buildBasePath(applicationId);
  };

  buildBasePath = applicationId => {
    if (!multi) {
      return '/product';
    }
    return `/${applicationId}/product`;
  };


  replace = (file, sku, ext) => {
    return new Promise((resolve, reject) => {
      let metadata;
      if (ext === "png") {
        metadata = {
          contentType: "image/png",
        };
      } else {
        metadata = {
          contentType: "image/jpeg",
        };
      }

      const filename = sku + '.' + ext;

      this.storage
        .ref(`${this.basePath}/${filename}`)
        .put(file, metadata)
        .then(
          snapshot => {
            snapshot.ref.getDownloadURL().then(
              url => {
                resolve({ code: 0, body: {url} });
              }
            )
          },
          error => {
            resolve({ code: -1 });
          }
        );
    });
  };
  defaultImage = (file) => {
    return new Promise((resolve, reject) => {
      const metadata = {
        contentType: "image/png",
      };
      this.storage
      .ref(`${this.basePath}/no_image.png`)
      .put(file, metadata)
      .then(
        snapshot => {
          snapshot.ref.getDownloadURL().then(
            url => {
              resolve({ code: 0, body: {url} });
            }
          )
        },
        error => {
          resolve({ code: -1 });
        }
      );
    })
  }
  delete_ = () => {
    return new Promise((resolve, reject) => {
      const list = {};
      this.collection.get().then(
        querySnapshot => {
          querySnapshot.docs.map(doc => {
            list[doc.id] = doc.data();
          });
          resolve({ code: 0, body: list });
        },
        error => {
          resolve({ code: -1, body: list });
        }
      );
    });
  };
}
